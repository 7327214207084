import { makeStyles } from "@material-ui/core/styles"
import React, { useState } from "react"
import DifficultyIndicator from "../../DifficultyIndicator/DifficultyIndicator"
import { Duration, getDurationDisplay, WorkoutVideo } from "../../types"
import clsx from "clsx"
import { Typography } from "@material-ui/core"
import Img from "gatsby-image"
import { isMobile } from "../../../../utils"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import FeaturedOverlay from "./FeaturedOverlay"
import { motion } from "framer-motion"
import EquipmentIndicator from "../../EquipmentIndicator/EquipmentIndicator"

export const widthXS = 280
export const widthSM = 180
export const width = 360
export const widthXL = 500
const largeWidth = 300
const ratio = 9 / 16
const useStyles = makeStyles((theme) => ({

  imageContainer: {
    position: "relative",
    "borderRadius": "2.5px",
    "WebkitBackdropFilter": "blur(15px)",
    "backdropFilter": "blur(15px)",
    "border": "solid 0.5px #414141",
    "letter-spacing": "normal",
    overflow: "hidden",
    [theme.breakpoints.down("xl")]: {
      "width": `100%`,
      "height": "auto",
      paddingTop: `calc(${ratio} * 100%)`
    }
  },
  fixedContainer: {
    "width": `${width}px`,
    "height": `${(width * ratio)}px`,
    [theme.breakpoints.down("xs")]: {
      "width": `${widthXS}px`,
      "height": `${(widthXS * ratio)}px`
    }, [theme.breakpoints.only("sm")]: {
      "width": `${widthSM}px`,
      "height": `${(widthSM * ratio)}px`
    }
    , [theme.breakpoints.up("xl")]: {
      "width": `${widthXL}px`,
      "height": `${(widthXL * ratio)}px`
    }
  },
  largeImage: {
    "width": `${largeWidth}px`,
    "height": `${(largeWidth * 9) / 16}px`,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0
    }
  },
  image: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    objectFit: "cover"
  },
  gif: {
    objectFit: "cover",
    opacity: 0
  },
  tint: {
    width: "100%",
    height: "100%",
    position: "absolute",
    bottom: "0",
    left: "0",
    zIndex: 0,
    "backgroundImage": "linear-gradient(to top, #000000, rgba(49, 49, 49, 0.12) 30%)",
    "&:hover": {}
  },
  metaContainer: {
    display: "flex",
    position: "absolute",
    "textAlign": "left",
    bottom: "5%",
    left: "4%",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
    alignContent: "center",
    textTransform: "uppercase"
  },
  equipmentContainer: {
    position: "absolute",
    "textAlign": "right",
    bottom: "5%",
    right: "4%",
    display: "none"
  },
  show: {
    display: "block"
  },
  hide: {
    display: "none"
  },
  gifAlwaysShow: {
    opacity: 1
  }
}))


export default function WorkoutImage({ workoutVideo, large, fixed, alwaysShowGif, featured, inviteFriendsClicked, hideMeta }: { workoutVideo: WorkoutVideo, large?: boolean, fixed?: boolean, alwaysShowGif?: boolean, featured?: boolean, hideMeta?: boolean, inviteFriendsClicked?: () => void | null }) {
  const classes = useStyles()
  const [showGif, setShowGif] = useState(false)
  const theme = useTheme()
  const smAndLarger = useMediaQuery(theme.breakpoints.up("sm"))

  const imageContainerStyle = clsx({
    [classes.imageContainer]: true, //always apply
    [classes.fixedContainer]: fixed
  })

  const metaStyle = clsx({
    [classes.metaContainer]: true, //always apply
    [classes.hide]: hideMeta ?? false
  })

  const equipmentStyle = clsx({
    [classes.equipmentContainer]: true, //always apply
    [classes.show]: (smAndLarger || fixed) && !(hideMeta ?? false)
  })

  const gifStyle = clsx({
    [classes.image]: true,
    [classes.gif]: true,
    [classes.gifAlwaysShow]: alwaysShowGif
  })


  const gifVariants = {
    show: {
      opacity: 1,
      transition: { duration: 0 }
    },
    hide: {
      opacity: 0,
      transition: { duration: 0 }
    }
  }

  const tintVariants = {
    show: {
      opacity: 1
    },
    hide: {
      opacity: 0.5,
      transition: {
        duration: 1
      }
    }
  }

  return (
    <div className={imageContainerStyle} onMouseEnter={() => setShowGif(!featured)}
         onMouseLeave={() => setShowGif(false)}>
      {workoutVideo.localImage == null && <img className={classes.image} src={workoutVideo.thumbnailUrl}/>}
      {workoutVideo.localImage != null && <Img style={{ position: `absolute` }} className={classes.image}
                                               fluid={workoutVideo.localImage.childImageSharp.fluid}/>}
      {workoutVideo.gifUrl && (!isMobile || alwaysShowGif) &&
      <motion.img initial={alwaysShowGif ? "show" : "hide"} animate={showGif || alwaysShowGif ? "show" : "hide"}
                  variants={gifVariants} className={gifStyle} src={workoutVideo.gifUrl}/>}
      <motion.div initial={"show"} animate={showGif ? "hide" : "show"} variants={tintVariants}
                  className={classes.tint}/>
      <Typography variant={"h6"}>
        <div className={metaStyle}>
          <DifficultyIndicator category={workoutVideo.mainDifficulty} large={large}/>
          <div style={{ marginLeft: "6px" }}>
            {workoutVideo.mainDifficulty}
          </div>
        </div>

        <div className={equipmentStyle}>
          <EquipmentIndicator equipments={workoutVideo.equipments} type={"normal"}/>
        </div>
      </Typography>

      {featured && <FeaturedOverlay title={workoutVideo.scheduleTitle}
                                    instructorKey={workoutVideo.instructors[0]}
                                    scheduleInfo={workoutVideo.scheduleInfo!}
                                    inviteFriendsClicked={inviteFriendsClicked!}
                                    duration={getDurationDisplay(workoutVideo.durationCategory as Duration)}/>}
    </div>)
}
