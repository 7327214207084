import React, { useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Box, Tooltip, TooltipProps, Typography } from "@material-ui/core"


export interface Props {
  shareLink: string,
  shareCopiedMessage: string
  showStartPrompt: boolean
}

const useStyles = makeStyles((theme) => ({
  shareLink: {
    color: theme.palette.primary.main,
    [`@media (hover: hover)`]: {
      "&:hover": {
        cursor: "pointer",
        opacity: 0.7
      }
    }
  },
  startMessage: {
    marginTop: theme.spacing(2)
  }
}))

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    fontSize: 11,
    color: "rgba(0, 0, 0, 0.87)"
  },
  tooltipPlacementBottom: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(0.5)
    }
  }
}))

function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap()

  return <Tooltip arrow classes={classes} {...props} />
}

export default function HostMessage(props: Props) {
  const classes = useStyles()
  const [linkCopied, setLinkCopied] = useState<boolean>(false)

  function shareLinkClicked() {
    setLinkCopied(true)
  }

  useEffect(() => {
    if (linkCopied) {
      const timer = setTimeout(() => {
        setLinkCopied(false)
      }, 3000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [linkCopied])

  return (<div>
    <Typography align={"center"} variant={"body2"}>
      Share the link below and workout live with friends (up to 8).
    </Typography>
    <BootstrapTooltip
      title={"Link copied!"}
      placement="bottom"
      open={linkCopied}
      className={classes.shareLink}
    >
      <CopyToClipboard text={props.shareCopiedMessage}
                       onCopy={shareLinkClicked}>
        <Typography align={"center"} variant={"body2"}>{props.shareLink}</Typography>
      </CopyToClipboard>
    </BootstrapTooltip>

    {props.showStartPrompt && <Typography align={"center"} variant={"body2"} className={classes.startMessage}>
      Hit the start button when everyone’s ready.
    </Typography>}

    {!props.showStartPrompt &&
    <Box mt={2}>
      <div/>
    </Box>}

  </div>)
}