import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { isBrowser } from "../../../../utils"
import RoundButton from "../../../../components/RoundButton"
import clsx from "clsx"
import moment from "moment"
import { ScheduleDateFormats, ScheduleInfo } from "../../types"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 2,
    display: "flex",
    backgroundColor: "rgba(0,0,0,0.84)"
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    justifyItems: "center",
    alignItems: "center",
    width: "70%",
    height: "100%"
  },

  image: {
    width: "28%",
    objectFit: "contain",
    position: "absolute",
    bottom: "0px",
    right: "4%"
  },

  kevinImage: {
    width: "35%"
  },

  reaganImage: {
    width: "24%"
  },

  join: {
    marginTop: theme.spacing(1.5)
  },

  date: {
    opacity: 0.4,
    whiteSpace: "pre-wrap"
  },

  time: {
    whiteSpace: "pre-wrap"
  },

  liveContainer: {
    display: "flex",
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(0.5)
  },

  liveCircle: {
    height: "6px",
    width: "6px",
    backgroundColor: theme.colorRed,
    marginTop: theme.spacing(0.9),
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0.6)
    }
  },

  liveText: {
    color: theme.colorRed,
    textTransform: "uppercase",
    position: "relative",
    left: theme.spacing(0.5)
  },
  hide: {
    display: "none"
  }

}))

export interface Props {
  scheduleInfo: ScheduleInfo
  title: string
  duration: string
  instructorKey: string
  inviteFriendsClicked: () => void
}

export default function FeaturedOverlay(props: Props) {

  const data = useStaticQuery(graphql`
      query {
          desiree : file(relativePath: { eq: "instructors/feature/desiree.png" }) {
              childImageSharp {
                  fluid(maxWidth: 260, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          faith : file(relativePath: { eq: "instructors/feature/faith.png" }) {
              childImageSharp {
                  fluid(maxWidth: 260, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          gino: file(relativePath: { eq: "instructors/feature/gino.png" }) {
              childImageSharp {
                  fluid(maxWidth: 260, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          janet: file(relativePath: { eq: "instructors/feature/janet.png" }) {
              childImageSharp {
                  fluid(maxWidth: 260, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          kevin_loo: file(relativePath: { eq: "instructors/feature/kevin.png" }) {
              childImageSharp {
                  fluid(maxWidth: 260, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          spancer: file(relativePath: { eq: "instructors/feature/spancer.png" }) {
              childImageSharp {
                  fluid(maxWidth: 260, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          reagan_kang: file(relativePath: { eq: "instructors/feature/reagan1.png" }) {
              childImageSharp {
                  fluid(maxWidth: 260, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          sakinah: file(relativePath: { eq: "instructors/feature/sakinah.png" }) {
              childImageSharp {
                  fluid(maxWidth: 260, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          steph: file(relativePath: { eq: "instructors/feature/steph.png" }) {
              childImageSharp {
                  fluid(maxWidth: 260, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          steph_monk: file(relativePath: { eq: "instructors/feature/stephyoga.png" }) {
              childImageSharp {
                  fluid(maxWidth: 260, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          tris: file(relativePath: { eq: "instructors/feature/tris.png" }) {
              childImageSharp {
                  fluid(maxWidth: 260, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          xiaohan: file(relativePath: { eq: "instructors/feature/xiaohan.png" }) {
              childImageSharp {
                  fluid(maxWidth: 260, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
      }
  `)

  const classes = useStyles()

  const liveStyle = clsx({
    [classes.liveContainer]: true,
    [classes.hide]: !props.scheduleInfo.isLive
  })

  function dayWithDateUI() {
    return <>
      <Typography className={classes.date} align={"center"} variant={"subtitle1"} display={"block"}>
        {isBrowser ? moment(props.scheduleInfo.startTimeInMs).format(ScheduleDateFormats.displayDayLong).toString() : "\n"}
      </Typography>
      <Typography className={classes.time} align={"center"} variant={"h2"} display={"block"}>
        {isBrowser ? moment(props.scheduleInfo.startTimeInMs).format(ScheduleDateFormats.displayTime).toUpperCase() : "\n"}
      </Typography>
    </>
  }

  function countdownUI() {
    return <>
      <Typography className={classes.date} align={"center"} variant={"subtitle1"} display={"block"}>
        {props.scheduleInfo.isLive ? "Time elapsed" : "Starting in"}
      </Typography>
      <Typography className={classes.time} align={"center"} variant={"h2"} display={"block"}>
        {props.scheduleInfo.countdown}
      </Typography>
    </>
  }

  return (
    <div
      className={classes.root}>
      <div className={classes.textContainer}>

        {!props.scheduleInfo!.countdown && dayWithDateUI()}
        {props.scheduleInfo!.countdown && countdownUI()}

        <RoundButton className={classes.join} selected={props.scheduleInfo.ctaState === "join"} symbol={"+"}
                     text={props.scheduleInfo.ctaState == "invite" ? "Invite your friends" : "Join this class"}
                     onClick={props.scheduleInfo.ctaState == "invite" ? props.inviteFriendsClicked : null}/>
      </div>
      {data[props.instructorKey] && props.instructorKey !== "kevin_loo" &&
      <Img className={classes.image} style={{ position: "absolute" }}
           imgStyle={{ objectFit: "contain", position: "absolute", width: "100%" }}
           fluid={data[props.instructorKey].childImageSharp.fluid}/>}
      {data[props.instructorKey] && props.instructorKey === "kevin_loo" &&
      <Img className={clsx(classes.image, classes.kevinImage)} style={{ position: "absolute" }}
           imgStyle={{ objectFit: "contain", position: "absolute", width: "100%" }}
           fluid={data[props.instructorKey].childImageSharp.fluid}/>}

      <div className={liveStyle}>
        <div className={classes.liveCircle}/>
        <Typography variant={"subtitle1"} className={classes.liveText}>live</Typography>
      </div>

    </div>
  )

}