import React, { useEffect, useState } from "react"
import StyledButton from "../../../components/StyledButton"
import { makeStyles } from "@material-ui/core/styles"
import { WORKOUTS_HOME } from "../../routing/Locations"
import { Link, navigate } from "gatsby"
import { width, widthSM, widthXL, widthXS } from "../../browse/WorkoutCard/WorkoutImage/WorkoutImage"
import { Box, Typography } from "@material-ui/core"
import { CountdownData, GroupInfo } from "../redux/types"
import HostMessage from "./HostMessage"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useTheme from "@material-ui/core/styles/useTheme"
import moment from "moment"
import { Difficulty, getDifficultyDisplay, ScheduleDateFormats } from "../../browse/types"
import CloseButton from "../../../components/CloseButton"
import AddToCalendar, { convertGroupInfoToCalendarProps } from "../../browse/Scheduled/AddToCalendar"
import EquipmentIndicator from "../../browse/EquipmentIndicator/EquipmentIndicator"

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 2,
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(3)
    }
  },

  buttonContainer: {
    marginTop: theme.spacing(5)
  },

  message: {
    marginTop: theme.spacing(2)
  },
  imageContainer: {
    display: "inline-block",
    verticalAlign: "top"
  },
  textContainer: {
    width: `${width}px`,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(7),
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: `${widthXS}px`
    },
    [theme.breakpoints.only("sm")]: {
      width: `${widthSM * 1.4}px`
    },
    [theme.breakpoints.up("xl")]: {
      width: `${widthXL * 0.8}px`
    }
  },
  date: {
    marginBottom: theme.spacing(0.4)
  },
  title: {
    marginBottom: theme.spacing(0.4)
  },
  duration: {
    textTransform: "uppercase"
  },
  line: {
    marginTop: theme.spacing(1.5),
    height: "0.3px",
    border: "solid 0.2px #ffffff"
  },
  subTitle: {},

  metaContainer: {
    display: "flex",
    marginTop: theme.spacing(0.5),
    alignItems: "center",
    justifyContent: "center"

  },

  info: {
    marginTop: theme.spacing(1)
  },
  endedContainer: {
    zIndex: 2,
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center"
  },
  difficulty: {
    whiteSpace: "pre-wrap"
  },

  hausmates: {
    whiteSpace: "pre-wrap"
  },

  largeCountdown: {
    fontSize: "120px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "140px"
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "160px"
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "120px"
  }
}))


export interface Props {
  groupInfo: GroupInfo
  classStarted: boolean
  userInteracted: boolean
  showStartUI: boolean
  showAddToCalendar: boolean
  scheduleClassStartTimeInMs?: number | null
  numHausmates?: number | null
  countdownData?: CountdownData | null
  hostStartClick?: (() => void) | undefined
  userInteractionClick?: (() => void) | undefined
}


function WorkoutWait(props: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const isScreenMDAndAbove = useMediaQuery(theme.breakpoints.up("md"))
  const showWorkoutInfo = isScreenMDAndAbove || !props.groupInfo.isHost

  function classStartingInCountdown(ticker: string) {
    return <div>
      <Typography align={"center"} variant={"body1"} display={"block"}>Starting in: </Typography>
      <Typography align={"center"} variant={"h1"} display={"block"}>{ticker}</Typography>
    </div>
  }

  function classCountdownLarge(ticker: string) {
    return (<div className={classes.container}>
      <Typography className={classes.largeCountdown} variant={"h2"} display={"block"}>{ticker}</Typography>
    </div>)
  }


  if (!props.groupInfo.alreadyEnded) {

    if (props.countdownData && props.countdownData.large) {
      return classCountdownLarge(props.countdownData.tickerText)
    }

    return (<div className={classes.container}>

      <div>
        {(props.scheduleClassStartTimeInMs ?? 0) > 0 && <Typography align={"center"} variant={"body2"}
                                                                    className={classes.date}>{moment(props.scheduleClassStartTimeInMs).format(ScheduleDateFormats.longDate)}</Typography>}
        <Typography variant={"h2"} align={"center"}>{props.groupInfo.videoTitle}</Typography>
        <div className={classes.metaContainer}>
          <EquipmentIndicator equipments={props.groupInfo.videoEquipments} type={"large"}/>
          <Typography className={classes.difficulty} variant={"body2"}
                      align={"center"}> • {getDifficultyDisplay(props.groupInfo.videoDifficulty as Difficulty)}</Typography>
          {(props.numHausmates ?? 0) > 0 &&
          <Typography className={classes.hausmates} variant={"body2"} align={"center"}> • {props.numHausmates} hausmates
            here</Typography>}
        </div>


        <div className={classes.textContainer}>


          <div className={classes.message}>

            {!props.groupInfo.isHost && !props.classStarted && !props.userInteracted &&
            <Typography align={"center"} variant={"body1"} display={"block"}>{props.groupInfo.hostName} has invited you
              to take a class together</Typography>}

            {!props.groupInfo.isHost && !props.classStarted && props.userInteracted && (props.scheduleClassStartTimeInMs ?? 0) <= 0 &&
            <Typography align={"center"} variant={"h3"} display={"block"}>Waiting for {props.groupInfo.hostName} to
              start class!</Typography>}

            {props.groupInfo.isHost &&
            <HostMessage shareLink={props.groupInfo.shareLink} shareCopiedMessage={props.groupInfo.shareLinkCopy!}
                         showStartPrompt={props.showStartUI}/>}

            {props.classStarted &&
            <Typography align={"center"} variant={"h2"} display={"block"}>Class in progress</Typography>}

            {props.countdownData && classStartingInCountdown(props.countdownData.tickerText)}
          </div>

          {!props.userInteracted &&
          <div className={classes.buttonContainer}>
            {props.showStartUI && <StyledButton text={"start"} onClick={() => props.hostStartClick!()}/>}
            {!props.showStartUI && <StyledButton text={"join now"} onClick={() => props.userInteractionClick!()}/>}
          </div>}

          {props.showAddToCalendar && <Box mt={2}>
            <AddToCalendar {...convertGroupInfoToCalendarProps(props.groupInfo, "center")}/>
          </Box>}

        </div>
      </div>

      {!props.userInteracted && !props.showStartUI && <CloseButton clicked={() => navigate(WORKOUTS_HOME)}/>}
    </div>)
  }

  return (
    <div className={classes.endedContainer}>
      <div>
        <Typography align={"center"} variant={"h2"} display={"block"}>This class ended. See you at the next one
          :)</Typography>
        <Box mt={6} width={`260px`} mr={`auto`} ml={`auto`}>
          <Link to={WORKOUTS_HOME} replace={true}><StyledButton className={classes.homeButton}
                                                                text="Browse Classes"/></Link>
        </Box>
      </div>
    </div>
  )

}


export function WorkoutIntroWithDivider({ title, subtitle, classTimestampInMS, description, showWorkoutInfo, numHausmates }: { title: string | null, subtitle: string | null | undefined, classTimestampInMS?: number | null | undefined, description: string | null, showWorkoutInfo: boolean, numHausmates?: number | null | undefined }) {

  const [classSimpleDate, setClassSimpleDate] = useState<string | null>(null)

  useEffect(() => {
    if (classTimestampInMS && classTimestampInMS > 0) {
      const date = moment(classTimestampInMS).format(ScheduleDateFormats.longDate)
      setClassSimpleDate(date)
    } else {
      setClassSimpleDate(null)
    }
  }, [classSimpleDate])

  const classes = useStyles()
  return (<>

    {classSimpleDate && <Typography variant={"body2"} className={classes.date}>{classSimpleDate}</Typography>}
    <Typography variant={"h3"} className={classes.title}>{title}</Typography>
    <Typography variant={"body2"}
                className={classes.subTitle}>{subtitle}{numHausmates && numHausmates > 0 ? ` • ${numHausmates} hausmates here` : ""}</Typography>
    <div className={classes.line}/>
    {showWorkoutInfo && <Typography className={classes.info} variant={"body2"}>
      {description}
    </Typography>}
  </>)
}

export default WorkoutWait
