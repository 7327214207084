import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { Difficulty } from "../types"
import React from "react"

const useStyles = makeStyles((theme) => ({
  base: {
    "width": "7px",
    "height": "7px",
    "borderRadius": "1px"
  },

  large: {
    "width": "7px",
    "height": "7px"
  },

  beginner: {
    "backgroundColor": "#27d9bf"
  },

  intermediate: {
    "backgroundColor": "rgb(250, 203, 62)"
  },

  advanced: {
    "backgroundColor": "rgb(252, 128, 15)"
  }
}))

export interface Props {
  category: string
  large?: boolean
}

export default function DifficultyIndicator(props: Props) {
  const classes = useStyles()

  const style = clsx({
    [classes.base]: true, //always apply
    [classes.beginner]: props.category === Difficulty.beginner.toString(),
    [classes.intermediate]: props.category === Difficulty.intermediate.toString(),
    [classes.advanced]: props.category === Difficulty.advanced.toString()
    //[classes.large]: props.large
  })

  return <div className={style}/>
}

