import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { Typography } from "@material-ui/core"
import { TapEffect } from "./AnimationEffects"


const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10.5px",
    border: `solid 1px ${theme.palette.primary.main}`,
    padding: theme.spacing(0.2, 1, 0.3, 1),
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.main,
    [`@media (hover: hover)`]: {
      "&:hover": {
        cursor: "pointer",
        color: "#000000",
        backgroundColor: theme.palette.primary.main
      }
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(0.1)
    }
  },

  selected: {
    backgroundColor: theme.palette.primary.main,
    color: "black",
    [`@media (hover: hover)`]: {
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: "transparent"
      }
    }
  },

  symbol: {
    lineHeight: "0px",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("xs")]: {
      lineHeight: "0px"
    }
  }
}))


export interface Props {
  text: string
  symbol?: string
  onClick?: ((event: React.MouseEvent<HTMLDivElement>) => void) | null
  className?: string
  selected?: boolean
}

export default function RoundButton(props: Props) {
  const classes = useStyles()

  const rootStyle = clsx({
    [classes.root]: true, //always apply
    [props.className ?? ""]: true,
    [classes.selected]: props.selected ?? false
  })

  return (

    <div className={rootStyle} onClick={(e) => {
      if (props.onClick) {
        e.stopPropagation()
        props.onClick(e)
      }
    }}>
      <TapEffect>
        {props.symbol && <Typography variant={"body2"} className={classes.symbol} paragraph={false}
                                     display={"inline"}>{props.symbol} </Typography>}
        <Typography variant={"subtitle2"} display={"inline"}>
          {props.text}
        </Typography>
      </TapEffect>
    </div>
  )
}