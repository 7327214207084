import { Duration, getDurationMinutes, WorkoutVideo } from "../types"
import { CalendarEvent, google, ics, outlook, yahoo } from "calendar-link"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { PropTypes, Typography } from "@material-ui/core"
import { isBrowser } from "../../../utils"
import dayjs from "dayjs"
import { GroupInfo } from "../../videochat/redux/types"


export interface Props {
  eventTitle: string
  eventDescription?: string
  startTime?: number | null
  duration?: [number, dayjs.UnitType];
  titleAlignment: PropTypes.Alignment
}

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    marginTop: theme.spacing(0.5),
    display: "flex",
    justifyContent: "space-between"
  },

  link: {
    color: theme.palette.primary.main,
    [`@media (hover: hover)`]: {
      "&:hover": {
        cursor: "pointer",
        opacity: 0.7
      }
    }
  }
}))

export default function AddToCalendar(props: Props) {
  const classes = useStyles()

  if (!isBrowser || !props.startTime) {
    return null
  }

  const event: CalendarEvent = {
    title: props.eventTitle,
    description: props.eventDescription,
    start: props.startTime,
    duration: props.duration,
    busy: true,
    url: window.document.URL
  }

  return <div>
    <Typography variant={"body2"} align={props.titleAlignment}>Add to calendar</Typography>
    <div className={classes.linkContainer}>
      <a className={classes.link} href={ics(event)}>iCal</a>
      <a className={classes.link} href={google(event)} target={"_blank"}>Google</a>
      <a className={classes.link} href={yahoo(event)} target={"_blank"}>Yahoo</a>
      <a className={classes.link} href={outlook(event)} target={"_blank"}>Outlook</a>
    </div>
  </div>
}

export function convertWorkoutToCalendarProps(w: WorkoutVideo, titleAlignment: PropTypes.Alignment): Props {
  return {
    eventTitle: w.pageTitle,
    eventDescription: w.description,
    startTime: w.scheduleInfo?.startTimeInMs,
    duration: [getDurationMinutes(w.durationCategory as Duration), "minute"],
    titleAlignment: titleAlignment
  }
}

export function convertGroupInfoToCalendarProps(g: GroupInfo, titleAlignment: PropTypes.Alignment): Props {
  return {
    eventTitle: g.videoPageTitle,
    eventDescription: g.videoDescription,
    startTime: g.startTime,
    duration: [getDurationMinutes(g.videoDurationCategory as Duration), "minute"],
    titleAlignment: titleAlignment
  }
}